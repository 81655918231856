<template>
  <div>
    <h3>Create new Specialist Request</h3>
    <form>
      <div class="form-group">
        <label for="select_cat">Select Category</label>
        <select
          class="form-control"
          id="select_cat"
          v-model="runnerRequest.runner_category_id"
        >
          <option
            v-for="runnerCategory in runnerCategories"
            :key="runnerCategory.id"
            :value="runnerCategory.id"
          >
            {{ runnerCategory.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="catDescription">Title</label>
        <input
          class="form-control"
          id="catTitle"
          v-model="runnerRequest.title"
        />
      </div>
      <div class="form-group">
        <label for="catDescription">Description</label>
        <textarea
          class="form-control"
          id="catDescription"
          rows="3"
          v-model="runnerRequest.description"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="catDescription">Phone number of Contact Person</label>
        <input
          class="form-control"
          id="catDescription"
          type="number"
          v-model="runnerRequest.contact"
        />
      </div>
      <div class="form-group">
        <label for="catDescription">Due Date</label>
        <input
          class="form-control"
          id="catDate"
          type="date"
          data-date-format="mm/dd/yyyy"
          v-model="runnerRequest.due_date"
        />
      </div>

      <button
        v-if="auth.isAuthenticated"
        :disabled="isLoading"
        type="submit"
        class="btn btn-primary ls-submit-button"
        @click.prevent="onCreateRunnerRequest()"
      >
        {{ isLoading ? "Processing..." : "Submit" }}
        <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
        <div
          v-if="isLoading"
          class="spinner-border text-light"
          role="status"
        ></div>
      </button>
      <router-link v-else :to="{ name: 'login' }">
        <button type="submit" class="btn btn-primary ls-submit-button">
          Submit
        </button>
      </router-link>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "Specialist",
  mounted() {
    this.getRunnersDetails();
    if (this.auth.isAuthenticated) runnerRequest.contact = this.auth.user.phone;
    $("#catDate").datepicker({
      weekStart: 1,
      daysOfWeekHighlighted: "6,0",
      autoclose: true,
      todayHighlight: true,
    });
    $("#catDate").datepicker("setDate", new Date());
    this.runnerRequest.due_date = new Date().toLocaleDateString("en-US");
  },
  data() {
    return {
      runnerCategories: [],
      runnerRequest: {
        title: "",
        description: "",
        runner_category_id: "",
        contact: "",
        due_date: "",
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    getRunnersDetails() {
      axios.get("runners/categories").then((response) => {
        //console.log("Get Runner Cat : " + JSON.stringify(response.data));
        this.runnerCategories = response.data;
      });
    },
    onCreateRunnerRequest() {
      //the request method to save the request
      let data = {
        runner_category_id: this.runnerRequest.runner_category_id,
        body: this.runnerRequest.description,
        title: this.runnerRequest.title,
      };

      this.isLoading = true;
      axios
        .post("runners/requests", data)
        .then((response) => {
          this.isLoading = false;
          if (response.status === 200 || response.status === 201) {
            this.message = "User successfully created";
            // setTimeout(function(){
            //   this.$router.push({name: 'admin_users'});
            // }, 500);
            this.$toasted.show("Request successfully created. ");
            this.runnerRequest.description = "";
            this.runnerRequest.runner_category_id = "";
            location.href = "/specialist/requests/view/" + response.data.id;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toasted.error("Saving failed, please try again");
        });
    },
  },
};
</script>
